import {generateThemes} from '@nfq/colors';

import type {GetThemeType} from '@nfq/colors';

export const BaseColors = {
    /** Error color ![#DF162E](https://via.placeholder.com/12/DF162E/DF162E.png) `#DF162E`. */
    error: '#DF162E',
    /** Neutral color light ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    neutral0: '#FFFFFF',
    /** Neutral color light 20 ![#F3F3F5](https://via.placeholder.com/12/F3F3F5/F3F3F5.png) `#F3F3F5`. */
    neutral20: '#F3F3F5',
    /** Neutral color light 40 ![#B4B4BE](https://via.placeholder.com/12/B4B4BE/B4B4BE.png) `#B4B4BE`. */
    neutral40: '#B4B4BE',
    /** Neutral color light 60 ![#73737D](https://via.placeholder.com/12/73737D/73737D.png) `#73737D`. */
    neutral60: '#73737D',
    /** Neutral color light 80 ![#3C3C46](https://via.placeholder.com/12/3C3C46/3C3C46.png) `#3C3C46`. */
    neutral80: '#3C3C46',
    /** Neutral color dark ![#00001E](https://via.placeholder.com/12/00001E/00001E.png) `#00001E`. */
    neutral100: '#00001E',
    /** Primary color ![#0050FF](https://via.placeholder.com/12/0050FF/0050FF.png) `#0050FF`. */
    primary: '#0050FF',
    /** Primary dark color ![#00008C](https://via.placeholder.com/12/00008C/00008C.png) `#00008C`. */
    primaryDark: '#00008C',
    /** Primary light color ![#82DCFA](https://via.placeholder.com/12/82DCFA/82DCFA.png) `#82DCFA`. */
    primaryLight: '#82DCFA',
    /** Primary soft color ![#3373FF](https://via.placeholder.com/12/3373FF/3373FF.png) `#3373FF`. */
    primarySoft: '#3373FF',
    /** Secondary 1 color ![#FFA55A](https://via.placeholder.com/12/FFA55A/FFA55A.png) `#FFA55A`. */
    secondary1: '#FFA55A',
    /** Secondary 2 color ![#F65D7C](https://via.placeholder.com/12/F65D7C/F65D7C.png) `#F65D7C`. */
    secondary2: '#F65D7C',
    /** Secondary 3 color ![#00DC7D](https://via.placeholder.com/12/00DC7D/00DC7D.png) `#00DC7D`. */
    secondary3: '#00DC7D',
    /** Secondary 4 color ![#A078FF](https://via.placeholder.com/12/A078FF/A078FF.png) `#A078FF`. */
    secondary4: '#A078FF',
    /** Text primary color ![#00001E](https://via.placeholder.com/12/00001E/00001E.png) `#00001E`. */
    textPrimary: '#00001E',
    /** Text primary invert ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    textPrimaryInvert: '#FFFFFF',
    /** Text secondary color ![#73737D](https://via.placeholder.com/12/73737D/73737D.png) `#73737D`. */
    textSecondary: '#73737D',
    /** Text secondary invert ![#F3F5F6](https://via.placeholder.com/12/F3F5F6/F3F5F6.png) `#F3F5F6`. */
    textSecondaryInvert: '#F3F5F6'
} as const;

export const DerivedColors = {} as const;

const BoxShadows = {
    /** The default dropshadow for markers. */
    markerShadow: '0px 1px 3px 0px rgba(1, 0, 33, 0.10), 0px 1px 2px 0px rgba(1, 0, 33, 0.06)',
    /** The default dropshadow for Popovers. */
    popOverShadow: '0px 0px 10px 5px rgba(25, 38, 48, 0.05)'
} as const;

export type BaseColorsType = GetThemeType<typeof BaseColors>;
export const {
    globalCss,
    shadows,
    themeColors,
    themes
} = generateThemes({
    baseColors: BaseColors,
    derivedColors: DerivedColors,
    shadows: BoxShadows
});